import React, { useState } from "react";
import {
  Avatar,
  Button,
  Heading,
  Image,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";

export const PhotoInput = React.forwardRef(
  ({ value, required, isAvatar, ...props }, ref) => {
    const [image, setImage] = useState(null);
    const [id, _] = useState((Math.random() + 1).toString(36).substring(7));

    function readURL(input) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
          setImage(e.target.result);
        };

        reader.readAsDataURL(input.files[0]);
      }
    }

    return (
      <VStack width="100%" align="flex-start">
        {(image && typeof image === typeof "") ||
        (value && typeof value === typeof "") ? (
          isAvatar ? (
            <Avatar src={image || value} />
          ) : (
            <Image
              minH="100px"
              maxH="200px"
              width="100%"
              objectFit="cover"
              height="auto"
              src={image || value}
            />
          )
        ) : (
          <></>
        )}
        {}
        {value ? (
          <Button
            onClick={() => {
              document.getElementById(id).click();
            }}
          >
            Trocar
          </Button>
        ) : (
          <Button
            colorScheme="green"
            onClick={() => {
              document.getElementById(id).click();
            }}
          >
            Adicionar
          </Button>
        )}
        <Input
          display="none"
          ref={ref}
          id={id}
          type="file"
          required={required && !value}
          accept="image/png image/jpg"
          {...props}
          onChange={(e) => {
            readURL(e.target);
            props.onChange(e);
          }}
        />
        ;
      </VStack>
    );
  }
);
