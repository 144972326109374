import {
  Button,
  HStack,
  Image,
  Input,
  Select,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ReactInputMask from "react-input-mask";
import { useMutation, useQuery } from "react-query";
import { createReferral } from "../../api/referral";
import { partiuClient } from "../../axios";

export function ReferrerForm() {
  const { handleSubmit, register } = useForm();
  const [selectedAgreement, setSelectedAgreement] = useState({});
  const toast = useToast();
  const { data: referrableAgreements = [] } = useQuery(
    "referrable-agreements",
    () => partiuClient.referral.listAgreements()
  );
  const createReferralMutation = useMutation(
    (data) => createReferral(data, selectedAgreement.id),
    {
      onSuccess() {
        toast({
          title: "Indicação criada com sucesso!",
          description:
            "Seu amigo logo deve receber um email com sua indicação!",
          status: "success",
        });
      },
      onError() {
        toast({
          title: "Email inválido",
          description: "O email inserido é inválido, tente novamente!",
          status: "error",
        });
      },
    }
  );

  return (
    <form
      style={{ width: "100%" }}
      onSubmit={handleSubmit(createReferralMutation.mutate)}
    >
      <VStack width="100%" gap={4}>
        <Select
          onChange={(e) => {
            setSelectedAgreement(
              e.target.value === "-1"
                ? {}
                : referrableAgreements.filter(
                  (ra) => ra.id.toString() === e.target.value
                )[0]
            );
          }}
        >
          <option value={-1}>Selecione um convênio</option>
          {referrableAgreements.map((ra) => (
            <option key={ra.id} value={ra.id}>
              {ra.name}
            </option>
          ))}
        </Select>
        {selectedAgreement.referral_image ? (
          <Image
            src={selectedAgreement.referral_image}
            objectFit="contain"
            width="100%"
            height="150px"
          />
        ) : (
          <></>
        )}
        <Text
          textAlign="justify"
          width="100%"
          dangerouslySetInnerHTML={{
            __html:
              selectedAgreement.referral_offer ||
              "Selecione um convênio acima para ver seus dados de indicação.",
          }}
        />
        {selectedAgreement.id ? (
          <>
            <Input
              {...register("name")}
              placeholder="Nome do Indicado"
              required
            />
            <ReactInputMask
              mask="(99) 9 9999-9999"
              required
              placeholder="Telefone do Indicado"
              style={{
                fontSize: "1rem",
                paddingLeft: "1rem",
                paddingRight: "1rem",
                height: "2.5rem",
                borderRadius: "0.375rem",
                border: "1px solid",
                borderColor: "inherit",
                width: "100%",
              }}
              {...register("telephone")}
            />
            <Input
              {...register("email")}
              placeholder="Email do Indicado"
              required
              type="email"
            />
          </>
        ) : (
          <></>
        )}
      </VStack>

      <HStack mt={8} width="100%">
        <Button isFullWidth type="ghost" mr={3} onClick={onclose}>
          Cancelar
        </Button>
        <Button
          isFullWidth
          type="submit"
          isLoading={createReferralMutation.isLoading}
          colorScheme="green"
        >
          Indicar
        </Button>
      </HStack>
    </form>
  );
}
