import { HStack, Stack } from "@chakra-ui/react";
import { faDollar, faTags } from "@fortawesome/free-solid-svg-icons";
import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getSales } from "../../api/sales";
import { partiuClient } from "../../axios";
import { CompanyContext } from "../../contexts/CompanyContext";
import { StatisticCard } from "../molecules/StatisticCard";

export function StoreStatistics() {
  const { store } = useContext(CompanyContext);
  const { data: sales = [], isLoading } = useQuery("sales", () =>
    partiuClient.salesApp.sales.listStoreSales(store.id)
  );

  const value = sales.map
    ? sales
        .map((s) => s.pdv_given_total || s.saved || 0)
        .reduce((p = 0, c) => p + c, 0)
    : 0;

  return (
    <Stack
      justify="flex-end"
      width="100%"
      direction={{ base: "column", md: "row" }}
      spacing={3}
    >
      <StatisticCard
        icon={faDollar}
        title="Vendas (este mês)"
        loading={isLoading}
        description={sales.length}
      />
      <StatisticCard
        icon={faDollar}
        title="Receita Vendas (este mês)"
        loading={isLoading}
        description={value}
        formatOptions={{
          style: "currency",
          currency: "BRL",
        }}
      />
      <StatisticCard
        icon={faTags}
        title="Desconto(s) Cadastrados"
        description={store.discounts.length}
      />
    </Stack>
  );
}
