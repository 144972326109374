import { Heading } from "@chakra-ui/layout";
import React from "react";

export function Subtext({ children }) {
  return (
    <Heading
      textTransform="lowercase !important"
      fontSize="12px !important"
      fontWeight="400"
      color="#777"
    >
      {children}
    </Heading>
  );
}
