import React, { useContext, useState } from "react";
import { Heading, OrderedList, Stack, Text, VStack } from "@chakra-ui/react";
import { DashboardTemplate } from "../templates/DashboardTemplate";
import { StoreStatistics } from "../components/organisms/StoreStatistics";
import { GraphBox } from "../components/molecules/GraphBox";
import { VirtualCardList } from "../components/molecules/VirtualCardsList";
import { GenerateSale } from "../components/organisms/GenerateSale";
import { ReferrerForm } from "../components/molecules/ReferrerForm";
import { partiuClient } from "../axios";
import { CompanyContext } from "../contexts/CompanyContext";
import { MonthSelector } from "../components/molecules/MonthSelector";
import { Graph } from "../components/atoms/Graph";
import { SaleList } from "../components/molecules/SaleList";
import { useQuery } from "react-query";
import { getSales } from "../api/sales";
import { VirtualCardHistory } from "../components/molecules/VirtualCardHistory";
import { VirtualCardHistoryList } from "../components/molecules/VirtualCardHistoryList";
import { getUnvalidatedVirtualCards } from "../api/virtual_cards";

export const Home = () => {
  const { store } = useContext(CompanyContext);
  const [date, setDate] = useState(new Date());
  const { data: sales = [], isLoading } = useQuery(["sales", date], () =>
    getSales(date, store.id)
  );

  return (
    <DashboardTemplate>
      <VStack spacing={6} width="100%" padding="20px 30px 20px 30px">
        <StoreStatistics />
        <Stack
            direction={{ base: "column", md: "row" }}
            spacing={5}
            justify="flex-end"
            width="100%"
        ><GraphBox
            title="Check-Ins"
            subtext="Aqui você registrará a venda para clientes Partiu! que gerarem o cartão virtual"
        >
          <VirtualCardList />
        </GraphBox></Stack>
        <Stack direction={{ base: "column", md: "row" }}
          spacing={5}
          justify="flex-end"
          width="100%">
          <GraphBox
              title="Indique um Cliente"
              subtext="Indique clientes e ganhe benefícios."
              maxWidth="400px"
          >
            <ReferrerForm />
          </GraphBox>
          <GraphBox
            maxWidth="400px"
            title="Gerar Venda Manual"
            subtext="Está em dúvida? Dê uma lida nesse passo a passo."
            spacing={3}
            align="flex-start"
          >
            <GenerateSale />
            <Heading pt={4}>Como Usar</Heading>
            <Text>O cliente deve:</Text>
            <OrderedList>
              <li>Abrir o app Partiu ou site.</li>
              <li>Buscar pela sua loja na aba parceiros locais.</li>
              <li>Clicar em fazer Check-in</li>
            </OrderedList>
            <Text>
              Quando o cliente fizer Check-in, ele deve aparecer nesta página na
              caixa de Cartões Virtuais.
            </Text>
            <Text>Digite o valor da venda e aperte em "Gerar Venda"</Text>
          </GraphBox>

        </Stack>
        <Stack
          direction={{ base: "column", md: "row" }}
          spacing={5}
          justify="flex-end"
          width="100%"
        >
          <GraphBox
            title="Vendas por dia"
            subtext="Veja aqui os descontos utilizados pelos clientes."
          >
            <MonthSelector date={date} setDate={setDate} />
            <Graph isLoading={isLoading} sales={sales} date={date} />
          </GraphBox>
        </Stack>
        <GraphBox
          title="Histórico de Vendas"
          subtext="Veja em detalhes todas as vendas do período"
        >
          <SaleList isLoading={isLoading} sales={sales} />
        </GraphBox>
        <GraphBox
          title="Histórico de Check-ins"
          subtext="Veja em detalhes todos os check-ins gerados"
        >
          <VirtualCardHistoryList isLoading={isLoading} />
        </GraphBox>
      </VStack>
    </DashboardTemplate>
  );
};
