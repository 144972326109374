import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import { createMyDiscount, updateMyDiscount } from "../../api/discounts";
import { DiscountForm } from "./DiscountForm";

export function DiscountModal({
  initialData = {},
  companyId,
  isOpen,
  onClose,
  storeId,
}) {
  return (
    <Modal
      closeOnOverlayClick={false}
      closeOnEsc={false}
      isOpen={isOpen}
      onClose={onClose}
      size="md"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {initialData?.name ? initialData.name : "Criando Desconto"}
        </ModalHeader>
        <ModalBody>
          <DiscountForm
            initialData={initialData}
            companyId={companyId}
            storeId={storeId}
            nextText="Salvar"
            updateFunc={updateMyDiscount}
            createFunc={createMyDiscount}
            onNext={() => {
              window.location.reload();
            }}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
