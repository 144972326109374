import React, { useEffect, useState } from "react";
import { GradientButton } from "../components/atoms/GradientButton";
import { axiosInstance, setAccessToken } from "../axios";
import { Input } from "../components/atoms/Input";
import { useParams } from "react-router-dom";
import { Center, CircularProgress } from "@chakra-ui/react";
import { loginPDV } from "../api/login";

export const LoginPage = ({ onLogin, errors }) => {
  let email = useState("");
  let password = useState("");
  let params = useParams();
  let [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const token = params.token;
    if (!token) return;

    localStorage.clear();
    window.history.pushState({}, "", "/");

    setAccessToken(token);
    axiosInstance
      .get("/profile/")
      .then(({ data }) => {
        _loginPDV(data.email, token);
      })
      .catch(() => alert("Não foi possível realizar o login automático."));
  }, []);

  const _loginPDV = (email, token) => {
    setIsLoading(true);
    setAccessToken(token);
    loginPDV(token, email, onLogin, () => {
      setIsLoading(false);
      alert(
        "Conta não é PDV. Para entender mais entre em contato com nosso suporte."
      );
    });
  };

  const login = () => {
    setIsLoading(true);
    axiosInstance
      .post("/users/tokens/", {
        username: email[0],
        password: password[0],
      })
      .then(({ data }) => _loginPDV(email[0], data.token))
      .catch(() => {
        setIsLoading(false);
        alert("Não foi encontrada conta PDV com esse email e senha.");
      });
  };

  return (
    <div class="login" style={{ width: "100%", display: "flex" }}>
      <div
        class="logo"
        style={{
          flex: 1,
          height: "100vh",
          justifyItems: "center",
          alignItems: "center",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <img style={{ width: "30%", marginRight: 150 }} src="/logo.png" />
      </div>
      <div
        style={{
          flex: 1,
          height: "100vh",
          backgroundColor: "#F7F6FB",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <div
          class="login-box"
          style={{
            width: "40%",
            backgroundColor: "white",
            padding: 20,
            boxShadow: "0 10px 30px rgba(0,0,0,0.07)",
            borderRadius: 10,
            color: "#2A2A2C",
          }}
        >
          {isLoading ? (
            <Center>
              <CircularProgress isIndeterminate />
            </Center>
          ) : (
            <>
              {" "}
              <h1 style={{ margin: 0, fontSize: 20, marginBottom: 20 }}>
                Ponto de Vendas
              </h1>
              <Input label="Usuário" field={email} />
              <Input type="password" label="Senha" field={password} />
              <GradientButton
                onClick={login}
                style={{ marginTop: 35 }}
                text="Entrar"
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const styles = {};
