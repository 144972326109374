import React from "react";
import { Navigate } from "react-router-dom";

export function RequireAuth({ email, company, children }) {
  if (!email || !company) {
    return <Navigate to="/login" />;
  }

  return children;
}
