import { Button, HStack, Text, useToast, VStack } from "@chakra-ui/react";
import { faCheck, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { updateRegistration } from "../../../../api/registration";
import { LabeledInput } from "../../../molecules/LabeledInput";
import { PhotoInput } from "../../../molecules/PhotoInput";

export const ImageForm = ({
  onNext,
  companyId,
  discountId,
  storeId,
  initialData,
  onReturn,
}) => {
  const toast = useToast();
  const { register, handleSubmit, setValue, watch, getValues } = useForm();
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState(initialData.logo);
  const [coverPhoto, setCoverPhoto] = useState(initialData.cover_photo);
  const [image, setImage] = useState(initialData.image);

  async function onSubmit(data) {
    let func;

    setLoading(true);

    func = updateRegistration;

    try {
      await func(2, {
        id: discountId,
        company: companyId,
        image: data["image"],
      });
      await func(0, {
        id: companyId,
        cover_photo: data["cover_photo"],
        logo: data["logo"],
      });
      setLoading(false);
    } catch (response) {
      setLoading(false);
      return toast({
        title: "Oops!",
        description:
          "Algo deu errado, verifique as informações e tente novamente.",
        status: "error",
      });
    }

    onNext();
  }

  watch((data, { name }) => {
    const value = data[name];

    if (name === "image") setImage(value);
    if (name === "cover_photo") setCoverPhoto(value);
    if (name === "logo") setLogo(value);
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Text fontSize="14px" color="#888888">
        Os campos são obrigatórios.
      </Text>
      <VStack paddingBottom={5} paddingTop={5} spacing={5}>
        <LabeledInput
          Component={PhotoInput}
          {...register("logo")}
          value={logo}
          isAvatar
          required={true}
          label="Logo da Empresa"
          detail="Aceita imagens PNG, JPG e JPEG"
          accept=".png, .jpg, .jpeg"
        />
        <LabeledInput
          Component={PhotoInput}
          {...register("cover_photo")}
          value={coverPhoto}
          required={true}
          label="Foto de Capa (no mínimo 1400x300)"
          detail="Aceita imagens PNG, JPG e JPEG"
          accept=".png, .jpg, .jpeg"
        />
        <LabeledInput
          Component={PhotoInput}
          {...register("image")}
          value={image}
          required={true}
          label="Imagem do produto ou serviço"
          detail="Aceita imagens PNG, JPG e JPEG"
          accept=".png, .jpg, .jpeg"
        />
        <HStack width="100%" justifyContent="space-between">
          <Button onClick={onReturn} colorScheme="gray" variant="ghost">
            <FontAwesomeIcon style={{ marginRight: 10 }} icon={faChevronLeft} />
            Voltar
          </Button>
          <Button isLoading={loading} colorScheme="green" type="submit">
            Finalizar{" "}
            <FontAwesomeIcon style={{ marginLeft: 10 }} icon={faCheck} />
          </Button>
        </HStack>
      </VStack>
    </form>
  );
};
