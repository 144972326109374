import React from "react";
import Modal from "react-modal";

export default function ({ title, text, modal }) {
  return (
    <Modal isOpen={modal[0]}>
      <h1>{title}</h1>
      <p>{text}</p>
      <button onClick={() => modal[1](false)}>Fechar</button>
    </Modal>
  );
}
