import { Button } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../../contexts/ThemeContext";
import { Heading } from "@chakra-ui/layout";
import { useCheckMobile } from "../../utils";

export const MenuLink = ({
  name,
  onClick = () => {},
  page = "",
  icon,
  hovered = false,
  outside = false,
  neverActive = false,
  disabled,
  ...style
}) => {
  const navigate = useNavigate();
  const [_, forceUpdate] = useState(0);
  const { secondaryColor } = useContext(ThemeContext);
  const isMobile = useCheckMobile();
  const locationSplit = window.location.pathname.split("/");
  const active =
    (!neverActive && page === "" && locationSplit.length == 2) ||
    (!neverActive &&
      locationSplit.length > 1 &&
      locationSplit[1] === page.replace("/", ""));

  hovered = hovered || isMobile;

  useEffect(() => {
    if (hovered) forceUpdate(_ + 1);
  }, [hovered]);

  return (
    <Button
      justifyContent="flex-start"
      borderRadius="0"
      display="flex"
      width="100% !important"
      overflow="none"
      transition="0.2s"
      disabled={disabled}
      leftIcon={
        <FontAwesomeIcon
          style={{
            width: !hovered ? "60px" : "60px",
            marginRight: !hovered ? "20px" : "0px",
            color: active ? secondaryColor : "inherit",
          }}
          size="lg"
          icon={icon}
        />
      }
      borderLeft={active ? `3px solid ${secondaryColor}` : "0px"}
      color={active ? secondaryColor : "#777"}
      padding="25px 10px"
      backgroundColor="transparent"
      _hover={
        !disabled
          ? {
              color: secondaryColor,
            }
          : {}
      }
      onClick={() => {
        if (outside) return window.open(page);
        navigate(page);
        onClick();
        forceUpdate(_ + 1);
      }}
      {...style}
    >
      <Heading
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
        key={_}
        fontSize="10px"
      >
        {hovered ? name : ""}
      </Heading>
    </Button>
  );
};
