import { axiosInstance, partiuClient } from "../axios";

export function getSales(date, storeId) {
  return axiosInstance
    .get(`/sales/?month=${date.getMonth() + 1}&year=${date.getFullYear()}`, {
      headers: {
        'User-Role': 'pdv',
        'User-Pdv': storeId
      }
    })
    .then(({ data }) => data.results);
}

export function createSale(
  discountId,
  virtualCode,
  pdvGivenTotal,
  force = false
) {
  const storeId = JSON.parse(localStorage.getItem("store") || "{}").id;
  return partiuClient.salesApp.sales.createStoreSale(storeId, {
    discount: discountId,
    virtual_card: virtualCode,
    pdv_given_total: pdvGivenTotal,
    force_usage: force,
  });
}
