import { Flex, Heading, HStack, Image } from "@chakra-ui/react";
import React, { useContext } from "react";
import { CompanyContext } from "../../contexts/CompanyContext";

export function TopMenu() {
  const { company } = useContext(CompanyContext);

  return (
    <Flex
      paddingLeft="30px"
      align="center"
      height="50px"
      width="100%"
      backgroundColor="#d82b60"
      boxShadow="0px 0px 5px #d82b60"
    >
      <Image
        height="50%"
        src="https://partiu-static.s3.amazonaws.com/partiu_logo_black_white.png"
      />
      <Heading
        textOverflow="ellipsis"
        paddingLeft="30px"
        color="white"
        fontSize="16px !important"
        flex="1"
      >
        PDV - {company.name}
      </Heading>
    </Flex>
  );
}
