import { axiosInstance } from "../axios";

export function getLanding(agreementName) {
  return axiosInstance
    .get("/landings/?slug=" + agreementName)
    .then(({ data }) => data);
}

export function loginPDV(token, email, onLogin = () => {}, onError = () => {}) {
  axiosInstance
    .get("/user/pdv_company/")
    .then(({ data }) => {
      localStorage.setItem("company", JSON.stringify(data.company));
      localStorage.setItem("store", JSON.stringify(data.store));
      localStorage.setItem("token", token);
      localStorage.setItem("email", email);

      onLogin({ company: data.company, store: data.store, email: email });
    })
    .catch(() => {
      onError();
    });
}
