import React, { useContext, useEffect } from "react";
import { Route, Routes as RouterRoutes, useNavigate } from "react-router-dom";
import { loginPDV } from "./api/login";
import { RequireAuth } from "./components/atoms/RequireAuth";
import { CompanyContext } from "./contexts/CompanyContext";
import { CompanyProfilePage } from "./screens/CompanyProfile";
import { DiscountsPage } from "./screens/Discounts";
import { Home } from "./screens/Home";
import { LoginPage } from "./screens/Login";
import { SalesPage } from "./screens/Sales";

export function Routes({ onLogin }) {
  const { company, email, update } = useContext(CompanyContext);

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token)
      loginPDV(token, email, ({ company, store, email }) => {
        update({
          company,
          store,
          email,
          update,
        });
      });
  }, []);

  return (
    <RouterRoutes>
      <Route
        path="/"
        element={
          <RequireAuth email={email} company={company}>
            <Home />
          </RequireAuth>
        }
      />
      <Route
        path="/vendas"
        element={
          <RequireAuth email={email} company={company}>
            <SalesPage />
          </RequireAuth>
        }
      />
      <Route
        path="/empresa"
        element={
          <RequireAuth email={email} company={company}>
            <CompanyProfilePage />
          </RequireAuth>
        }
      />
      <Route
        path="/meus-descontos"
        element={
          <RequireAuth email={email} company={company}>
            <DiscountsPage />
          </RequireAuth>
        }
      />
      <Route path="/login" element={<LoginPage onLogin={onLogin} />} />
      <Route path="/login/:token" element={<LoginPage onLogin={onLogin} />} />
    </RouterRoutes>
  );
}
