import { Button, HStack } from "@chakra-ui/react";
import React from "react";

export function Pagination({ totalPages, setCurrentPage, currentPage }) {
  return (
    <HStack>
      {new Array(totalPages).fill(0).map((_, index) => <Button variant="ghost" isActive={index + 1 === currentPage} onClick={() => setCurrentPage(index + 1)}>{index + 1}</Button>)}
    </HStack>

  )
}
