import {
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Modal,
  VStack,
  Checkbox,
  HStack,
  ModalFooter,
  Button,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { updateRegistration } from "../../api/registration";
import Terms from "../molecules/Terms";

export function ApproveTermsModal({ isOpen, onClose, companyId, ...props }) {
  const [approved, setApproved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  function onApprove() {
    setIsLoading(true);
    updateRegistration(0, {
      id: companyId,
      approved_terms_date: new Date().toISOString(),
    })
      .then(() => {
        localStorage.setItem("setPassword", true);
        setIsLoading(false);
        onClose();
      })
      .catch(() => {
        setIsLoading(false);
        toast({
          title: "Oops!",
          description:
            "Erro ao aprovar termos, entre em contato com nosso suporte",
          status: "error",
        });
      });
  }

  function onDeny() {
    updateRegistration(0, { id: companyId, approved_data: false })
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        toast({
          title: "Oops!",
          description:
            "Erro reiniciar formulário, entre em contato com nosso suporte",
          status: "error",
        });
      });
  }

  return (
    <Modal
      closeOnOverlayClick={false}
      closeOnEsc={false}
      isOpen={isOpen}
      size="3xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Contrato Partiu - {props.name}</ModalHeader>
        <ModalBody>
          <VStack height="50vh" overflow="scroll" width="100%" margin="auto">
            <Terms {...props} />
          </VStack>
          <HStack
            onClick={() => setApproved(!approved)}
            cursor="pointer"
            marginTop="40px"
          >
            <Checkbox
              isChecked={approved}
              onChange={(val) => setApproved(val)}
            />
            <Text>
              Estou de acordo com o contrato e desejo disponibilizar minha loja
              no Clube Partiu.
            </Text>
          </HStack>
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          <Button
            isLoading={isLoading}
            onClick={onDeny}
            variant="ghost"
            colorScheme="red"
          >
            Mudar Dados
          </Button>
          <Button
            isLoading={isLoading}
            onClick={onApprove}
            colorScheme="green"
            disabled={!approved}
          >
            Assinar Digitalmente
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
