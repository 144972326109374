import {
  Box,
  Center,
  CircularProgress,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";
import { faFrown, faSadCry } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Sale } from "./Sale";

export function SaleList({ sales, isLoading }) {
  if (isLoading)
    return (
      <Center>
        <CircularProgress isIndeterminate />
      </Center>
    );

  if (sales.length === 0) {
    return (
      <VStack width="100%" paddingTop="50px" spacing={5} justify="center">
        <FontAwesomeIcon size="4x" icon={faFrown} />
        <Text textAlign="center" fontSize="16px">
          Oops! Parece que não temos nenhuma venda ainda.
          <br /> Está com dúvidas? Entre em contato pelo email:
          suporte@partiu.com.br
        </Text>
      </VStack>
    );
  }

  return (
    <VStack width="100%">
      {sales.map((sale, index) => (
        <VStack spacing={5} width="100%" padding="10px 0px">
          <Sale sale={sale} />
          {index < sales.length - 1 ? (
            <Box width="100%" borderTop="1px solid #eeeeee" />
          ) : (
            <></>
          )}
        </VStack>
      ))}
    </VStack>
  );
}
