import { Button, Flex, Heading, Image, Stack, VStack } from "@chakra-ui/react";
import {
  faAddressCard,
  faChartBar,
  faChartLine,
  faCreditCard,
  faHome,
  faMobile,
  faPowerOff,
  faServer,
  faTicketAlt,
  faUsers,
  faStore,
  faTags,
  faBuilding,
  faDollar,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import { MenuLink } from "../atoms/MenuLink";
import { setAccessToken } from "../../axios";
import { ThemeContext } from "../../contexts/ThemeContext";

export const Menu = ({ full = false, onClose = () => { } }) => {
  const {
    logo,
    menuColor,
    allow_product_selling,
    user_limit,
    promocode_limit,
    allow_push_notifications,
  } = useContext(ThemeContext);
  const [hovered, setHovered] = useState(false);
  return (
    <Flex
      position="fixed"
      left="0px"
      top="0px"
      direction="column"
      width={{ base: "70px", md: "95px" }}
      transition="0.4s"
      _hover={{
        width: full ? "100%" : "250px",
        boxShadow: "1px 2px 10px rgba(0,0,0,0.1)",
      }}
      backgroundColor="#FFFFFF"
      borderRight="1px solid #E2E8F0"
      height="100%"
      zIndex="100"
      align="center"
      onMouseEnter={() => setHovered(true)}
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Flex
        position="relative"
        zIndex="101"
        width="100%"
        justifyContent="center"
        marginTop="20px"
      >
        <Image
          padding="5px"
          backgroundColor={menuColor}
          borderRadius="10px"
          boxSize="50px"
          objectFit="contain"
          src={logo}
        />
      </Flex>
      <Stack
        align="flex-start"
        marginTop="0"
        width="100%"
        spacing={0}
        height="100%"
        flex="1"
        paddingTop="20px"
      >
        <VStack align="flex-start" width="95%" margin="auto 0px">
          <MenuLink
            hovered={hovered}
            onClick={onClose}
            page="/"
            name="Home"
            icon={faHome}
          />
          <MenuLink
            hovered={hovered}
            onClick={onClose}
            page="/empresa"
            name="Meus Dados"
            icon={faBuilding}
          />
          <MenuLink
            hovered={hovered}
            onClick={onClose}
            page="/meus-descontos"
            name="Meus Descontos"
            icon={faTags}
          />
          <MenuLink
            hovered={hovered}
            onClick={onClose}
            page="#"
            disabled
            name="Como Usar (em breve)"
            icon={faQuestionCircle}
          />
        </VStack>
        <MenuLink
          hovered={hovered}
          onClick={() => {
            localStorage.clear();
            setAccessToken(null);
            window.location.reload();
          }}
          name="Sair"
          neverActive
          marginTop="auto !important"
          marginBottom="20px"
          icon={faPowerOff}
        />
      </Stack>
    </Flex>
  );
};
