import { Heading, Text, VStack } from "@chakra-ui/react";
import React from "react";

export function LabeledText({ label, children, containerStyle = {} }) {
  return (
    <VStack {...containerStyle} width="fit-content" align="flex-start">
      <Heading whiteSpace="nowrap" fontSize="12px !important">
        {label}
      </Heading>
      <Text whiteSpace="nowrap" fontSize="16px">
        {children}
      </Text>
    </VStack>
  );
}
