import { VStack } from "@chakra-ui/react";
import React, { useContext } from "react";
import { GraphBox } from "../components/molecules/GraphBox";
import { MainForm } from "../components/organisms/MainForm";
import { CompanyContext } from "../contexts/CompanyContext";
import { DashboardTemplate } from "../templates/DashboardTemplate";

export function CompanyProfilePage() {
  const { company, store } = useContext(CompanyContext);
  return (
    <DashboardTemplate>
      <VStack spacing={6} width="100%" padding="20px 30px 20px 30px">
        <GraphBox
          title="Dados da Empresa"
          subtext="Esses dados não podem ser alterados. Qualquer dúvida entre em contato com o nosso suporte"
        >
          <MainForm disabled initialData={{ ...company, ...store }} />
        </GraphBox>
      </VStack>
    </DashboardTemplate>
  );
}
