import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { deleteMyDiscount } from "../../api/discounts";

export function DeleteDiscountModal({ discount, isOpen, onClose }) {
  return (
    <Modal
      closeOnOverlayClick={false}
      closeOnEsc={false}
      isOpen={isOpen}
      onClose={onClose}
      size="md"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Deseja desativar o desconto {discount.name}?</ModalHeader>
        <ModalBody>
          <Text>
            Uma vez desativado, será necessária a aprovação da Partiu para
            reativa-lo.
          </Text>
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          <Button variant="ghost" onClick={() => onClose()} colorScheme="blue">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              deleteMyDiscount(discount.id).then(() => {
                window.location.reload();
              });
            }}
            colorScheme="red"
          >
            Desativar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
