import React, { useState, useEffect } from "react";
import { setAccessToken } from "./axios";
import { useNavigate } from "react-router-dom";
import { defaultPartiuTheme, ThemeContext } from "./contexts/ThemeContext";
import { CompanyContext, initialCompanyData } from "./contexts/CompanyContext";
import { Routes } from "./Routes";
import { AbsoluteCenter, CircularProgress } from "@chakra-ui/react";

function App() {
  const navigation = useNavigate();
  const [companyData, setCompanyData] = useState(initialCompanyData);
  const [theme, setTheme] = useState(defaultPartiuTheme);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let company = JSON.parse(localStorage.getItem("company"));
    let store = JSON.parse(localStorage.getItem("store") || "null");
    let token = localStorage.getItem("token");
    let email = localStorage.getItem("email");

    if (!company || !token || !email) return setIsLoading(false);

    setCompanyData({
      update: setCompanyData,
      company,
      store,
      email,
    });

    setAccessToken(token);
    setTheme({ ...theme, logo: company.logo });
  }, []);

  useEffect(() => {
    if (companyData.company == null) return;
    setIsLoading(false);
  }, [companyData]);

  const onLogin = ({ company, store, email }) => {
    setTheme({ ...theme, logo: company.logo });
    setCompanyData({
      update: setCompanyData,
      company,
      store,
      email,
    });
    navigation("/");
  };

  if (isLoading) {
    return (
      <AbsoluteCenter>
        <CircularProgress isIndeterminate />
      </AbsoluteCenter>
    );
  }

  return (
    <ThemeContext.Provider value={theme}>
      <CompanyContext.Provider value={companyData}>
        <Routes onLogin={onLogin} />
      </CompanyContext.Provider>
    </ThemeContext.Provider>
  );
}

export default App;
