import React from "react";

export const GradientButton = ({ text, onClick, style, disabled = false }) => (
  <button
    onClick={onClick}
    disabled={disabled}
    style={{
      background: disabled ? "gray" : "linear-gradient(270deg, #F7941F 0%, #D91A5D 101.42%)",
      border: 0,
      borderRadius: 10,
      width: "100%",
      height: 40,
      color: "white",
      fontSize: 14,
      opacity: disabled ? 0.3 : 1,
      fontWeight: "bold",
      cursor: "pointer",
      ...style,
    }}
  >
    {text}
  </button>
);
