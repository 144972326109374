import {
  Button,
  HStack,
  Link,
  Spacer,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import {
  faAdd,
  faCheck,
  faEye,
  faPen,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import { useQuery } from "react-query";
import { getMyDiscounts } from "../api/discounts";
import { GraphBox } from "../components/molecules/GraphBox";
import { DeleteDiscountModal } from "../components/organisms/DeleteDiscountModal";
import { DiscountModal } from "../components/organisms/DiscountModal";
import { StoreStatistics } from "../components/organisms/StoreStatistics";
import { CompanyContext } from "../contexts/CompanyContext";
import { DashboardTemplate } from "../templates/DashboardTemplate";

export function DiscountsPage() {
  const { company, store } = useContext(CompanyContext);
  const { data: discounts = [], isLoading } = useQuery(
    "discounts",
    getMyDiscounts
  );
  const [isDiscountModalOpen, setIsDiscountModalOpen] = useState(false);
  const [isDeleteDiscountModalOpen, setIsDeleteDiscountModalOpen] =
    useState(false);
  const [selectedDiscount, setSelectedDiscount] = useState({});

  const types = {
    buy_and_gain: "Compre e Ganhe",
    treat: "Mimo",
    discount: "Desconto na conta",
  };
  return (
    <DashboardTemplate>
      <VStack spacing={6} width="100%" padding="20px 30px 20px 30px">
        <DiscountModal
          isOpen={isDiscountModalOpen}
          initialData={selectedDiscount}
          companyId={company.id}
          storeId={store.id}
          onClose={() => setIsDiscountModalOpen(false)}
        />
        <DeleteDiscountModal
          isOpen={isDeleteDiscountModalOpen}
          discount={selectedDiscount}
          onClose={() => setIsDeleteDiscountModalOpen(false)}
        />
        <StoreStatistics />
        <GraphBox
          title="Meus Descontos"
          isLoading={isLoading}
          padding="0px"
          subtext="Aqui você pode desativar, alterar ou criar novos descontos. Lembre-se que toda alteração será avaliada pela nossa equipe antes de ser lançada."
        >
          <Table width="100%">
            <Thead>
              <Tr color="black">
                <Th>Nome</Th>
                <Th>Modo</Th>
                <Th>Tipo</Th>
                <Th>Regulamento</Th>
                <Th>Status</Th>
                <Th>Ações</Th>
              </Tr>
            </Thead>
            <Tbody>
              {discounts.map((d) => (
                <Tr>
                  <Td whiteSpace="nowrap">{d.name}</Td>
                  <Td textTransform="capitalize">{d.mode}</Td>
                  <Td>{types[d.type]}</Td>
                  <Td fontSize="12px">{d.rules}</Td>
                  <Td whiteSpace="nowrap">
                    {d.pending_approval
                      ? "Em Análise"
                      : d.active
                      ? "Aprovado"
                      : "Desativado"}
                  </Td>
                  <Td>
                    <HStack>
                      <Button
                        size="sm"
                        colorScheme="green"
                        disabled={
                          company.status !== "approved" ||
                          d.pending_approval ||
                          !d.active
                        }
                        leftIcon={<FontAwesomeIcon icon={faEye} />}
                        onClick={() => {
                          window.open(
                            `https://app.partiu.com.br/detalhes/${d.mode}/${
                              d.mode === "local" ? store.id : d.id
                            }/`,
                            "_blank"
                          );
                        }}
                      >
                        Ver
                      </Button>
                      <Button
                        size="sm"
                        colorScheme="green"
                        disabled={d.must_replace || d.pending_approval}
                        leftIcon={
                          <FontAwesomeIcon icon={d.active ? faPen : faCheck} />
                        }
                        onClick={() => {
                          setSelectedDiscount(d);
                          setIsDiscountModalOpen(true);
                        }}
                      >
                        {d.active ? "Editar" : "Reativar"}
                      </Button>
                      {d.active ? (
                        <Button
                          size="sm"
                          colorScheme="red"
                          onClick={() => {
                            setSelectedDiscount(d);
                            setIsDeleteDiscountModalOpen(true);
                          }}
                          leftIcon={<FontAwesomeIcon icon={faTrash} />}
                        >
                          Desativar
                        </Button>
                      ) : (
                        <></>
                      )}
                    </HStack>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </GraphBox>
        <Button
          colorScheme="green"
          position="fixed"
          bottom="50px"
          right="50px"
          borderRadius="2rem"
          height="50px"
          width="220px"
          boxShadow="0px 5px 10px rgba(0,0,0,0.2)"
          onClick={() => {
            setSelectedDiscount({});
            setIsDiscountModalOpen(true);
          }}
          leftIcon={
            <FontAwesomeIcon
              style={{ paddingRight: "5px" }}
              size="xl"
              icon={faAdd}
            />
          }
        >
          Adicionar Desconto
        </Button>
      </VStack>
    </DashboardTemplate>
  );
}
