import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { partiuClient } from "../../axios";
import { LabeledInput } from "../molecules/LabeledInput";

export const SetPasswordModal = ({ isOpen, onClose }) => {
  const toast = useToast();
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState();

  async function onSubmit() {
    setIsLoading(true);

    if (password.length < 8) {
      return toast({
        title: "Oops!",
        status: "warning",
        description: "Sua senha deve ter pelo menos 8 caracteres.",
      });
    }

    await partiuClient.profile
      .update({ password })
      .then(() => {
        localStorage.removeItem("setPassword");
        setIsLoading(false);
        onClose();
      })
      .catch(() => {
        setIsLoading(false);
        toast({
          title: "Oops!",
          status: "error",
          description:
            "A sua senha não é segura o suficiente. Re-leia as regras e tente novamente.",
        });
      });
  }

  return (
    <Modal closeOnOverlayClick={false} closeOnEsc={false} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Crie sua Senha</ModalHeader>
        <ModalBody>
          <Text paddingBottom="10px">
            Crie sua senha para acesso do sistema PDV
          </Text>
          <LabeledInput
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Digite sua senha"
            detail="Pelo menos 8 caracteres, não pode ser parecido com seu email, não pode usar sequências como: 123, abc, etc..."
            type="password"
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onSubmit} isLoading={isLoading} colorScheme="green">
            Continuar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
