import { Box, Flex, Heading, Spacer, Text, VStack } from "@chakra-ui/react";
import {
  faCashRegister,
  faMobile,
  faPhoneSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getVirtualCards } from "../../api/virtual_cards";
import { partiuClient } from "../../axios";
import { CompanyContext } from "../../contexts/CompanyContext";
import { VirtualCard } from "./VirtualCard";

export function VirtualCardList() {
  const { store } = useContext(CompanyContext);
  const { data: virtualCards = [], refetch } = useQuery("virtualCard", () =>
    partiuClient.salesApp.virtualCards.listStoreVirtualCards(store.id)
  );

  useEffect(() => {
    const interval = setInterval(refetch, 10000);

    return () => clearInterval(interval);
  }, [refetch]);

  if (virtualCards.length === 0) {
    return (
      <VStack padding="40px 0px" spacing={5}>
        <FontAwesomeIcon size="3x" icon={faCashRegister} />
        <Heading>Nenhum Check-In</Heading>
        <Text fontSize="14px">
          Se estiver com dúvida, leia a caixa "Como Usar" abaixo.
        </Text>
      </VStack>
    );
  }

  return (
    <VStack paddingTop="0px" justify="space-between" width="100%">
      {virtualCards.map((card, index) => (
        <VStack spacing={5} width="100%" padding="10px 0px">
          <VirtualCard {...card} />
          {index < virtualCards.length - 1 ? (
            <Box width="100%" borderTop="1px solid #eeeeee" />
          ) : (
            <></>
          )}
        </VStack>
      ))}
    </VStack>
  );
}
