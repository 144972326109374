import React, { useState, useEffect, useContext } from "react";
import { axiosInstance, partiuClient } from "../../axios";
import { GradientButton } from "../atoms/GradientButton";
import Modal from "./Modal";
import { Heading, Text, VStack, Input, Select, HStack, CircularProgress } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faLock } from "@fortawesome/free-solid-svg-icons";
import { CompanyContext } from "../../contexts/CompanyContext";
import { UserData } from "../molecules/UserData";
import CurrencyInput from "react-currency-input-field";

export const GenerateSale = () => {
  const error = useState(false);
  const virtualCard = useState("");
  const discounts = useState([]);
  const selectedDiscount = useState("");
  const total = useState("");
  const modal = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalText, setModalText] = useState("");
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { company, store } = useContext(CompanyContext);

  useEffect(() => {
    axiosInstance.get("/discounts/?company=" + company.id).then(({ data }) => {
      discounts[1](data.results);

      if (data.results.length > 0) selectedDiscount[1](data.results[0].id);
    });
  }, []);

  useEffect(() => {
    if (virtualCard[0].length != 11) return () => { };

    setIsLoading(true)

    axiosInstance.get(`/user/cpf/${virtualCard[0]}/`).then(({ data }) => {
      setUserData(data)
    }).catch(() => {
      error[1](true);
      setModalTitle("Código Inválido!");
      setModalText(
        "O código ou CPF não está presente em nossos banco de dados. Por favor, verifique o código novamente."
      );
      modal[1](true);
      virtualCard[1]("")
      setUserData(null);
    }).finally(() => {
      setIsLoading(false)
    })
  }, [virtualCard[0]])

  const generate = () => {
    partiuClient.salesApp.sales
      .createStoreSale(store.id, {
        virtual_card: virtualCard[0],
        discount_id: selectedDiscount[0],
        pdv_given_total: total[0].replace(",", "."),
      })
      .then(() => {
        virtualCard[1]("");
        window.location.reload();
        setModalTitle("Sucesso!");
        setModalText(
          "Usuário é membro da Partiu. Venda foi adicionada ao banco de dados."
        );
        modal[1](true);
      })
      .catch(() => {
        error[1](true);
        setModalTitle("Código Inválido!");
        setModalText(
          "O código não está presente em nossos banco de dados. Por favor, verifique o código novamente."
        );
        modal[1](true);
      });
  };

  if (company.status !== "approved") {
    const status = {
      "pending-approval": "Pendente de aprovação da Partiu",
      "pending-terms": "Entre em contato com o suporte",
      approved: "Entre em contato com o suporte",
      "pending-training": "Pendente de treinamento",
    };
    return (
      <VStack width="100%" margin="auto" alignItems="flex-start">
        <FontAwesomeIcon
          icon={faLock}
          fontSize="40px"
          style={{ alignSelf: "center", paddingBottom: "20px" }}
        />
        <Heading fontSize="16px">Em processo de aprovação.</Heading>
        <Text paddingBottom="10px">
          <span style={{ fontWeight: "bold" }}>Status: </span>{" "}
          {status[company.status]}
        </Text>
        <Text>
          Quando sua empresa for aprovada, será possível adicionar vendas no
          sistema.
        </Text>
        <Text>
          Está com alguma dúvida? Envie-nos um email:{" "}
          <a href="mailto:suporte@partiu.com.br">suporte@partiu.com.br</a>
        </Text>
      </VStack>
    );
  }

  return (
    <VStack width="100%" spacing={4}>
      <HStack width="100%">
        {userData ? <UserData {...userData} /> : <Input
          placeholder="Insira o número do cartão virtual ou CPF"
          label="Cartão Virtual ou CPF"
          errorMessage="Código inválido ou expirado."
          onChange={(e) => virtualCard[1](e.target.value)}
          disabled={isLoading || userData}
          value={virtualCard[0]}
        />}
        {isLoading ?
          <CircularProgress size="25px" isIndeterminate /> : <></>}
        {userData ?
          <FontAwesomeIcon icon={faCheck} color="green" /> : <></>}
      </HStack>
      {userData ?
        <VStack spacing={4} width="100%">
          <Select
            value={selectedDiscount[0]}
            onChange={(e) => selectedDiscount[1](e.target.value)}
          >
            <option>Selecione um desconto</option>
            {discounts[0].map((d) => (
              <option value={d.id}>{d.name}</option>
            ))}
          </Select>
          <CurrencyInput
            placeholder="Valor da Venda"
            label="Valor da Venda"
            decimalsLimit={2}
            decimalSeparator=","
            groupSeparator="."
            defaultValue={0}
            prefix="R$ "
            style={{
              width: "100%",
              border: "1px solid rgb(226, 232, 240)",
              padding: "7px 15px",
              borderRadius: "5px"
            }}
            allowNegativeValue={false}
            onValueChange={(value) => total[1](value)}
            value={total[0]}
          />
          <HStack width="100%">
            <GradientButton onClick={generate} text="Gerar Venda" />
            <button
              style={{
                border: 0,
                backgroundColor: "#d4d4d4",
                borderRadius: 10,
                width: "100%",
                height: 40,
                color: "white",
                fontSize: 14,
                fontWeight: "bold",
                cursor: "pointer",
              }}
              onClick={() => {
                virtualCard[1]("")
                setUserData(null)
              }}
            >
              Cancelar
            </button>
          </HStack>
        </VStack> : <></>}
      <Modal title={modalTitle} text={modalText} modal={modal} />
    </VStack>
  );
};
