import { Stack, VStack } from "@chakra-ui/react";
import React, { useContext } from "react";
import { ApproveTermsModal } from "../components/organisms/ApproveTermsModal";
import { Menu } from "../components/organisms/Menu";
import { RegistrationModal } from "../components/organisms/RegistrationModal";
import { SetPasswordModal } from "../components/organisms/SetPasswordModal";
import { TopMenu } from "../components/organisms/TopMenu";
import { CompanyContext } from "../contexts/CompanyContext";

export const DashboardTemplate = ({ children }) => {
  const { company, store } = useContext(CompanyContext);

  function getRegistrationModal() {
    if (!company.approved_data) {
      return (
        <RegistrationModal
          company={company}
          store={store}
          isOpen={true}
          onClose={() => {
            window.location.reload();
          }}
        />
      );
    } else if (!company.approved_terms_date) {
      return (
        <ApproveTermsModal
          companyId={company.id}
          {...store.discounts[0]}
          {...store}
          {...company}
          isOpen={true}
          onClose={() => {
            window.location.reload();
          }}
        />
      );
    } else if (localStorage.getItem("setPassword")) {
      return (
        <SetPasswordModal
          isOpen={true}
          onClose={() => window.location.reload()}
        />
      );
    }

    return <></>;
  }

  return (
    <Stack
      backgroundColor="#F5F5F5"
      direction={{ base: "column-reverse", md: "row" }}
      width="100%"
      minH="100vh"
    >
      {getRegistrationModal()}
      <Menu />
      <VStack
        align="flex-start"
        paddingLeft={{ base: "65px", md: "85px" }}
        width="100%"
        boxSizing="border-box"
        h="100%"
        spacing={3}
      >
        <TopMenu />
        {children}
      </VStack>
    </Stack>
  );
};
