import { Avatar, HStack } from "@chakra-ui/react";
import React from "react";
import { LabeledText } from "./LabeledText";

export function VirtualCardHistory({ virtualCard }) {
  return (
    <HStack display="flex" width="100%">
      <Avatar color="white" src={virtualCard?.user?.image || virtualCard?.user?.active_agreement?.logo} />
      <LabeledText containerStyle={{ flex: 2 }} label="Nome Completo">
        {virtualCard?.user?.full_name || "Cliente sem nome"}
      </LabeledText>
      <LabeledText containerStyle={{ flex: 1 }} label="Data">
        {new Date(virtualCard?.created_at).toLocaleDateString('pt-BR')}
      </LabeledText>
      <LabeledText containerStyle={{ flex: 1 }} label="Desconto">
        {virtualCard?.discount?.name || virtualCard?.sale?.discount?.name}
      </LabeledText>
      <LabeledText containerStyle={{ flex: 1 }} label="Código">
        {virtualCard?.code}
      </LabeledText>
    </HStack>
  );
}
