import React, { useEffect, useState } from "react";
import { Chart } from "react-charts";

function daysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}

export const Graph = ({ sales, isLoading, date }) => {
  const [salesArray, setSalesArray] = useState([]);

  useEffect(() => {
    let salesPerDay = {};
    let currDay = 1;

    new Array(daysInMonth(date.getMonth(), date.getFullYear()))
      .fill(0)
      .forEach(() => {
        salesPerDay[currDay + "/" + (date.getMonth() + 1)] = 0;
        currDay++;
      });

    sales.forEach((s) => {
      const dt = new Date(s.created_at);
      const key = dt.getDate() + "/" + (dt.getMonth() + 1);

      salesPerDay[key] = (salesPerDay[key] || 0) + 1;
    });

    console.log(salesPerDay);

    setSalesArray([
      ...Object.keys(salesPerDay).map((spd) => [spd, salesPerDay[spd]]),
    ]);
  }, [sales])

  const data = React.useMemo(
    () => [
      {
        label: "Vendas",
        data: salesArray,
      },
    ],
    [salesArray]
  );

  const series = React.useMemo(
    () => ({
      type: "bar",
    }),
    []
  );
  const axes = React.useMemo(
    () => [
      { primary: true, type: "ordinal", position: "bottom", show: "false" },
      { position: "left", type: "linear", show: false },
    ],
    []
  );
  return (
    <div style={{ height: 200, width: "100%", marginTop: 20 }}>
      <Chart
        getSeriesStyle={() => ({
          color: "#D91A5D",
        })}
        axes={axes}
        series={series}
        data={data}
        tooltip
      />
    </div>
  );
};
