import { axiosInstanceV2 } from "../axios";
import { jsonToFormData } from "./registration";

export function getMyDiscounts() {
  return axiosInstanceV2
    .get("/promotion/pdv/promotions/")
    .then(({ data }) => data);
}

export function updateMyDiscount(step, data) {
  delete data["stores"];
  delete data["exclude_agreements"]
  delete data["exclusive_agreements"]
  return axiosInstanceV2
    .patch(`/promotion/pdv/promotions/${data.id}/`, jsonToFormData(data))
    .then(({ data }) => data);
}

export function createMyDiscount(step, data) {
  return axiosInstanceV2
    .post(`/promotion/pdv/promotions/`, jsonToFormData(data))
    .then(({ data }) => data);
}

export function deleteMyDiscount(id) {
  return axiosInstanceV2
    .delete(`/promotion/pdv/promotions/${id}/`)
    .then(({ data }) => data);
}
