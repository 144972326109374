import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Progress,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { DiscountForm } from "../DiscountForm";
import { ImageForm } from "./components/ImageForm";
import { MainForm } from "../MainForm";
import { Introduction } from "./components/Introduction";
import { FormComplete } from "./components/FormComplete";
import MapPositionConfirm from "./components/MapPositionConfirm";

export const RegistrationModal = ({ isOpen, onClose, company, store }) => {
  const [step, setStep] = useState(0);
  const [companyInfo, setCompanyInfo] = useState(company);
  const [storeInfo, setStoreInfo] = useState(store);
  const [discountInfo, setDiscountInfo] = useState(
    store.discounts.length > 0 ? store.discounts[0] : {}
  );

  const steps = [
    {
      title: "Cadastro de Empresa Parceira",
      size: "md",
      component: <Introduction onNext={() => setStep(1)} />,
    },
    {
      title: "Informações Essenciais",
      size: "5xl",
      component: (
        <MainForm
          initialData={{ ...companyInfo, ...storeInfo }}
          companyId={companyInfo.id}
          storeId={storeInfo.id}
          onNext={({ company, store }) => {
            setCompanyInfo(company);
            setStoreInfo(store);
            setStep(2);
          }}
          onReturn={() => setStep(0)}
        />
      ),
    },
    {
      title: "Confirme a Localização",
      size: "md",
      component: (
        <MapPositionConfirm
          data={storeInfo}
          storeId={storeInfo.id}
          onNext={() => {
            setStep(3);
          }}
          onReturn={() => setStep(1)}
        />
      ),
    },
    {
      title: "Informações do Desconto",
      size: "md",
      component: (
        <DiscountForm
          storeId={storeInfo.id}
          companyId={companyInfo.id}
          initialData={discountInfo}
          onNext={(data) => {
            setDiscountInfo(data);
            setStep(4);
          }}
          onReturn={() => setStep(2)}
        />
      ),
    },
    {
      title: "Informações de Imagem",
      size: "md",
      component: (
        <ImageForm
          storeId={storeInfo.id}
          companyId={companyInfo.id}
          initialData={{ ...discountInfo, ...companyInfo, ...storeInfo }}
          discountId={discountInfo.id}
          onNext={() => setStep(5)}
          onReturn={() => setStep(3)}
        />
      ),
    },
    {
      title: "Cadastro Concluido!",
      size: "md",
      component: (
        <FormComplete
          companyId={companyInfo.id}
          onNext={() => {
            setCompanyInfo({});
            setStoreInfo({});
            onClose();
          }}
        />
      ),
    },
  ];

  return (
    <Modal
      closeOnOverlayClick={false}
      closeOnEsc={false}
      isOpen={isOpen}
      onClose={onClose}
      size={steps[step].size}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <>
            {steps[step].title}
            <Text
              marginTop="10px"
              fontWeight="400"
              fontSize="12px"
              textTransform="capitalize"
              color="#888888"
            >
              Passo {Math.min(step + 1, 4)} / {steps.length - 1}
            </Text>
            <Progress
              value={(Math.min(step + 2, 5) * 100) / steps.length - 1}
              borderRadius="10px"
              hasStripe={true}
              colorScheme={step === steps.length - 1 ? "green" : "blue"}
              isAnimated={true}
              transform
              marginTop="10px"
            />
          </>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>{steps[step].component}</ModalBody>
      </ModalContent>
    </Modal>
  );
};
