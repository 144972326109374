import React from "react";

export const Input = ({
  label,
  field,
  placeholder,
  type,
  error,
  errorMessage,
}) => (
  <div style={{ marginTop: 15, display: "flex", flexDirection: "column" }}>
    <label
      style={{
        fontSize: 14,
        color: "#424A60",
        marginBottom: 0,
      }}
    >
      {label}
    </label>
    <input
      style={{
        marginTop: 5,
        borderRadius: 10,
        border: "1.5px solid #D4D4D4",
        padding: "5px 0px 5px 10px",
        ...(error && error[0] ? { borderColor: "red" } : {}),
      }}
      type={type}
      value={field[0]}
      placeholder={placeholder}
      onChange={(e) => {
        field[1](e.target.value);

        if (error) error[1](false);
      }}
    />
    {error && error[0] && errorMessage ? (
      <p
        style={{
          margin: 0,
          color: "red",
          fontSize: 8,
          marginLeft: 10,
          marginTop: 2,
        }}
      >
        {errorMessage}
      </p>
    ) : (
      <div />
    )}
  </div>
);
