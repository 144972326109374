import { Avatar, Heading, HStack, Stack, VStack } from '@chakra-ui/react'
import React from 'react'

export function UserData({full_name, image, agreement }) {
    if (!full_name) return <UserData user={{ full_name: `Cliente` }} agreement={agreement} />

    return <HStack spacing={5}
        width="100%">
        <Avatar src={image || agreement?.logo} />
        <VStack align="flex-start">
            <Heading fontSize="18pt">{full_name}</Heading>
            <Heading fontSize="8pt">{agreement?.name}</Heading>
        </VStack>
    </HStack>
}