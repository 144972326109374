import {
  Button,
  Heading,
  HStack,
  Stack,
  Text,
  Textarea,
  useToast,
  VStack,
  Select,
} from "@chakra-ui/react";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { updateRegistration } from "../../api/registration";
import { partiuClient } from "../../axios";
import { InputMask } from "../atoms/InputMask";
import { LabeledInput } from "../molecules/LabeledInput";

export const MainForm = ({
  onNext,
  companyId,
  storeId,
  initialData,
  onReturn,
  disabled = false,
}) => {
  const toast = useToast();
  const { data: categories = [] } = useQuery(
    "categories",
    async () => await partiuClient.categories.list()
  );
  const { register, handleSubmit, setValue, getValues, watch } = useForm();
  const [category, setCategory] = useState(getValues("field_of_activity"));
  const [cep, setCep] = useState(getValues("postal_code"));
  const [cnpj, setCnpj] = useState(getValues("cnpj"));
  const [telephone, setTelephone] = useState(getValues("telephone"));
  const [cepToLoad, setCepToLoad] = useState();
  const [inputDisabled, setInputDisabled] = useState(false);
  const [cnpjToLoad, setCnpjToLoad] = useState();
  const [loading, setLoading] = useState(false);

  async function onSubmit(data) {
    let func;
    let company;
    let store;

    setLoading(true);

    func = updateRegistration;

    if (data["telephone"].indexOf("_") !== -1) {
      setLoading(false);
      return toast({
        title: "Telefone/Celular inválido",
        description: `O número de telefone/celular ${data["telephone"]} não é válido.`,
        status: "error",
      });
    }

    try {
      company = await func(0, { ...data, id: companyId });
      data["company"] = company.id;
      data["street"] += ", " + data["number"] + ", " + data["complement"];
      store = await func(1, { ...data, id: storeId });
      setLoading(false);
    } catch (response) {
      console.log(response);
      setLoading(false);
      return toast({
        title: "Oops!",
        description:
          "Algo deu errado, verifique as informações e tente novamente.",
        status: "error",
      });
    }

    onNext({ company, store });
  }

  watch((data, { name }) => {
    if (name === "field_of_activity") setCategory(data[name]);
    if (name === "telephone") setTelephone(data[name]);

    if (name === "auto_postal_code") {
      let cep = data.auto_postal_code
        .replace(/\_/g, "")
        .replace(/\-/g, "")
        .replace(/\./g, "");
      setCep(cep);
      setValue("postal_code", cep);
      if (cep != null && cep.length === 8 && cep !== cepToLoad) {
        setCepToLoad(cep);
      }
    }

    if (name === "auto_cnpj" && data.auto_cnpj) {
      let cnpj = data.auto_cnpj
        .replace(/\_/g, "")
        .replace(/\./g, "")
        .replace(/\-/g, "")
        .replace(/\//g, "");
      setCnpj(cnpj);
      setValue("cnpj", cnpj);
      if (cnpj != null && cnpj.length === 14 && cnpj !== cnpjToLoad) {
        setCnpjToLoad(cnpj);
      }
    }
  });

  useEffect(() => {
    if (cepToLoad == null) return;

    partiuClient.api
      .getAddressFromCEP(cepToLoad)
      .then((data) => {
        setValue("street", `${data.logradouro} ${data.complemento}`);
        setValue("neighborhood", data.bairro);
        setValue("state", data.uf);
        setValue("city", data.localidade);
        setValue("complement", data.complemento);
      })
      .catch(() => {});
  }, [cepToLoad, setValue]);

  useEffect(() => {
    if (cnpjToLoad == null) return;

    partiuClient.api
      .getCompanyDataFromCNPJ(cnpjToLoad)
      .then((data) => {
        setInputDisabled(false);
        setValue("auto_postal_code", data.cep);
        setValue("corporate_name", data.nome);
        setValue("name", data.fantasia);
        setValue(
          "telephone",
          data.telefone
            .replace(/\(/g, "")
            .replace(/\)/g, "")
            .replace(/\ /g, "")
            .replace(/\-/g, "")
        );
      })
      .catch(() => {
        setInputDisabled(false);
      });
  }, [cnpjToLoad, setValue]);

  useEffect(() => {
    if (!initialData) return;

    Object.entries(initialData).forEach(([key, value]) => {
      setValue(key, value);
    });

    setValue("auto_postal_code", initialData["postal_code"]);
    setValue("auto_cnpj", initialData["cnpj"]);
  }, [initialData]);

  return (
    <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
      <VStack padding="20px 0px" width="100%">
        <Stack
          paddingBottom={5}
          width="100%"
          paddingTop={5}
          display="flex"
          direction={{ base: "column", lg: "row" }}
        >
          <VStack
            borderRight={{ sm: "0", lg: "1px solid #D3D3D3" }}
            paddingRight={{ sm: 0, lg: 5 }}
            flex="1"
            spacing={5}
            height="100%"
          >
            <Heading
              alignSelf="flex-start"
              fontSize="17px !important"
              paddingBottom="20px"
            >
              Dados da Empresa
            </Heading>
            <LabeledInput
              label="CNPJ *"
              {...register("auto_cnpj")}
              borderWidth={inputDisabled ? "2px" : "1px"}
              value={cnpj}
              detail="Os dados serão automaticamente buscados pela integração com a Receita"
              Component={InputMask}
              forceDisable={disabled}
              disabled={disabled}
              mask="99.999.999/9999-99"
            />
            <LabeledInput
              label="Razão Social *"
              {...register("corporate_name")}
              required
              autoHighlight
              disabled={inputDisabled || disabled}
            />
            <LabeledInput
              label="Nome Fantasia *"
              {...register("name")}
              required
              autoHighlight
              disabled={inputDisabled || disabled}
            />
            <LabeledInput
              {...register("field_of_activity")}
              placeholder="Selecione uma categoria"
              required
              Component={Select}
              disabled={inputDisabled || disabled}
              value={category}
              label="Categoria"
            >
              {categories.map((c) => (
                <option key={c.id} value={c.id}>
                  {c.name}
                </option>
              ))}
            </LabeledInput>
            <LabeledInput
              {...register("description")}
              required={true}
              placeholder="Exemplo: Jac Café, um.lugar aconchegante com.diversas opções de cafés e salgados e doces deliciosos."
              resize="none"
              disabled={inputDisabled || disabled}
              minHeight="136px"
              label="Descrição da loja *"
              Component={Textarea}
            />
            <LabeledInput
              {...register("site")}
              placeholder="www.sualoja.com.br"
              disabled={inputDisabled || disabled}
              label="Site da loja"
            />
          </VStack>
          <VStack
            paddingLeft={{ sm: 0, lg: 5 }}
            borderRight={{ sm: "0", lg: "1px solid #D3D3D3" }}
            paddingRight={{ sm: 0, lg: 5 }}
            flex="1"
            height="100%"
            spacing={5}
          >
            <Heading
              alignSelf="flex-start"
              fontSize="17px !important"
              paddingBottom="20px"
              paddingTop={{ base: "20px", lg: "0px" }}
            >
              Endereço da Loja
            </Heading>
            <LabeledInput
              mask="99999-999"
              {...register("auto_postal_code")}
              value={cep}
              required={true}
              label="CEP *"
              placeholder="CEP"
              forceDisable
              Component={InputMask}
              disabled={inputDisabled || disabled}
            />
            <LabeledInput
              label="Rua *"
              {...register("street")}
              required={true}
              disabled={inputDisabled || disabled}
              placeholder="Endereço *"
            />
            <LabeledInput
              label="Número *"
              {...register("number")}
              disabled={inputDisabled || disabled}
              required={true}
              placeholder=""
            />
            <LabeledInput
              label="Complemento"
              {...register("complement")}
              disabled={inputDisabled || disabled}
              required={false}
              placeholder=""
            />
            <LabeledInput
              {...register("neighborhood")}
              disabled={inputDisabled || disabled}
              required={true}
              placeholder="Bairro *"
              label="Bairro *"
            />
            <LabeledInput
              {...register("city")}
              label="Cidade *"
              disabled={inputDisabled || disabled}
              required={true}
              placeholder="Cidade"
            />
            <LabeledInput
              {...register("state")}
              required={true}
              disabled={inputDisabled || disabled}
              label="Estado *"
              maxLength="2"
              placeholder="Estado"
            />
          </VStack>
          <VStack
            paddingLeft={{ sm: 0, lg: 5 }}
            flex="1"
            height="100%"
            spacing={5}
          >
            <Heading
              alignSelf="flex-start"
              fontSize="17px !important"
              paddingBottom="20px"
              paddingTop={{ base: "20px", lg: "0px" }}
            >
              Dados para contato
            </Heading>
            <LabeledInput
              {...register("owner")}
              required={true}
              disabled={inputDisabled || disabled}
              placeholder=""
              label="Nome do Dono *"
            />
            <LabeledInput
              {...register("email")}
              required={true}
              placeholder=""
              disabled={inputDisabled || disabled}
              label="Email do Dono *"
            />
            <LabeledInput
              {...register("telephone")}
              value={telephone}
              required={true}
              forceDisable
              placeholder=""
              disabled={inputDisabled || disabled}
              label="Telefone do Dono *"
              Component={InputMask}
              mask={
                telephone && telephone?.length > 5 && telephone[5] === "9"
                  ? "(99) 9 9999-9999"
                  : "(99) 9999-9999"
              }
            />
          </VStack>
        </Stack>
        <HStack
          display={onNext ? "flex" : "none"}
          width="100%"
          justifyContent="space-between"
        >
          <Button
            isLoading={loading}
            onClick={onReturn}
            colorScheme="gray"
            variant="ghost"
          >
            <FontAwesomeIcon style={{ marginRight: 10 }} icon={faChevronLeft} />
            Voltar
          </Button>
          <Button isLoading={loading} colorScheme="green" type="submit">
            Próximo{" "}
            <FontAwesomeIcon style={{ marginLeft: 10 }} icon={faChevronRight} />
          </Button>
        </HStack>
      </VStack>
    </form>
  );
};
