import { axiosInstanceV2, axiosInstance } from "../axios";

export function getVirtualCards() {
  return axiosInstanceV2
    .get(`/utilization/pdv/virtual_cards/`)
    .then(({ data }) => data);
}

export function getUnvalidatedVirtualCards(storeId, page = 1) {
  return axiosInstance
    .get(`/virtual-cards/?page=${page}&validated=false`, {
      headers: {
        'User-Role': 'pdv',
        'User-Pdv': storeId
      }
    }).then(({ data }) => data)

}
