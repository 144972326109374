import { Avatar, HStack } from "@chakra-ui/react";
import React from "react";
import { LabeledText } from "./LabeledText";

export function Sale({ sale }) {
  return (
    <HStack display="flex" width="100%">
      <Avatar color="white" src={sale?.user?.image || sale?.user?.active_agreement?.logo || sale?.agreement?.logo} />
      <LabeledText containerStyle={{ flex: 2 }} label="Nome Completo">
        {sale?.user?.full_name || `Cliente ${sale?.agreement?.name}`}
      </LabeledText>
      <LabeledText containerStyle={{ flex: 1 }} label="Data">
        {new Date(sale?.created_at).toLocaleDateString('pt-BR')}
      </LabeledText>
      <LabeledText containerStyle={{ flex: 1 }} label="Desconto">
        {sale?.discount?.name}
      </LabeledText>
      <LabeledText containerStyle={{ flex: 1 }} label="Total">
        {new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(sale?.pdv_given_total || sale?.discount?.original_price) + (!sale?.pdv_given_total ? " (Estimado)" : "")}
      </LabeledText>
    </HStack>
  );
}
