import React from "react";

export const MonthSelector = ({ date, setDate }) => {
  const MONTHS = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  const alterDate = (i) => {
    setDate(new Date(date.setMonth(date.getMonth() + i)));
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <i
        style={{ color: "#979797", cursor: "pointer" }}
        className="fas fa-chevron-left"
        onClick={() => alterDate(-1)}
      />
      <p
        style={{
          margin: "20px 10px",
          color: "#f7941f",
          fontSize: 16,
          fontWeight: "bold",
          width: 150,
          textAlign: "center",
        }}
      >
        {MONTHS[date.getMonth()]} de {date.getFullYear()}
      </p>
      <i
        style={{ color: "#979797", cursor: "pointer" }}
        className="fas fa-chevron-right"
        onClick={() => alterDate(1)}
      />
    </div>
  );
};
