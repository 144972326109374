import { Button, Text, VStack } from "@chakra-ui/react";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { updateRegistration } from "../../../../api/registration";

export const FormComplete = ({ onNext, companyId }) => {
  async function onSubmit() {
    await updateRegistration(0, { id: companyId, approved_data: true });

    onNext();
  }

  return (
    <VStack paddingBottom="10px">
      <Text>Os seus dados já foram atualizados em nosso sistema.</Text>
      <Text>
        Logo nossa equipe entrará em contato com você para determinar os
        próximos passos!
      </Text>
      <Text>
        Para acessar ao sistema, por favor confirme os dados enviados apertando
        no botão abaixo.
      </Text>
      <Button alignSelf="flex-end" onClick={onSubmit} colorScheme="green">
        Confirmo os Dados{" "}
        <FontAwesomeIcon style={{ marginLeft: 10 }} icon={faChevronRight} />
      </Button>
    </VStack>
  );
};
