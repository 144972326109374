import React from "react";
import { HoverBox } from "../atoms/HoverBox";
import { Box, Heading, VStack, Center } from "@chakra-ui/layout";
import { Subtext } from "../atoms/Subtext";
import { CircularProgress } from "@chakra-ui/react";

export const GraphBox = ({
  title,
  subtext,
  children,
  flex = null,
  spacing = 0,
  align = "center",
  isLoading = false,
  padding = "20px",
  ...props
}) => (
  <Box flex={flex} width="100%" {...props}>
    <HoverBox marginBottom="20px" hover={false} flexDirection="column">
      <VStack spacing={0} width="100%" align="flex-start" padding="20px">
        <Heading>{title}</Heading>
        {subtext ? <Subtext>{subtext}</Subtext> : <></>}
      </VStack>
      {isLoading ? (
        <Center padding="30px 0px">
          <CircularProgress isIndeterminate />
        </Center>
      ) : (
        <VStack
          padding={padding}
          paddingTop="10px"
          align={align}
          spacing={spacing}
        >
          {children}
        </VStack>
      )}
    </HoverBox>
  </Box>
);
