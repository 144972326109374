import { axiosInstance } from "../axios";

export function jsonToFormData(data) {
  const formData = new FormData();
  const imageFields = ["logo", "image", "cover_photo"];

  Object.keys(data).forEach((k) => {
    if (imageFields.indexOf(k) !== -1) {
      if (typeof data[k] === typeof "" || !data[k] || data[k].length === 0)
        return;

      formData.append(k, data[k][0]);
      return;
    }

    if (data[k] !== null) formData.append(k, data[k]);
  });

  return formData;
}

export const createRegistration = (step, data) => {
  const formData = jsonToFormData(data);

  return axiosInstance
    .post(`/agreement_staff/companies/?step=${step}`, formData)
    .then(({ data }) => data);
};

export const updateRegistration = (step, data) => {
  const formData = jsonToFormData(data);

  return axiosInstance
    .patch(`/agreement_staff/companies/${data.id}/?step=${step}`, formData)
    .then(({ data }) => data);
};
