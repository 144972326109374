import React from "react";

let defaultPartiuTheme = {
  id: null,
  agreementName: "Partiu",
  logo: "/img/logo.png",
  primaryColor: "#e53a35",
  primaryFontColor: "white",
  secondaryColor: "#fb8c00",
  menuColor: "white",
  menuFontColor: "black",
  canLoginCPF: false,
  price: 0,
  faq: false,
  banners: [],
};

export { defaultPartiuTheme };

export const ThemeContext = React.createContext(defaultPartiuTheme);
