import { Stack, VStack } from "@chakra-ui/react";
import { faTags } from "@fortawesome/free-solid-svg-icons";
import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getSales } from "../api/sales";
import { partiuClient } from "../axios";
import { Graph } from "../components/atoms/Graph";
import { GraphBox } from "../components/molecules/GraphBox";
import { MonthSelector } from "../components/molecules/MonthSelector";
import { SaleList } from "../components/molecules/SaleList";
import { StatisticCard } from "../components/molecules/StatisticCard";
import { StoreStatistics } from "../components/organisms/StoreStatistics";
import { CompanyContext } from "../contexts/CompanyContext";
import { DashboardTemplate } from "../templates/DashboardTemplate";

export function SalesPage() {
  const { store } = useContext(CompanyContext);
  const { data: sales = [], isLoading } = useQuery("sales", () =>
    partiuClient.salesApp.sales.listStoreSales(store.id)
  );
  const [date, setDate] = useState(new Date());

  return (
    <DashboardTemplate>
      <VStack spacing={6} width="100%" padding="20px 30px 20px 30px">
        <StoreStatistics sales={sales} />
        <GraphBox
          title="Vendas por dia"
          subtext="Veja aqui os descontos utilizados pelos clientes."
        >
          <MonthSelector date={date} setDate={setDate} />
          <Graph isLoading={isLoading} sales={sales} date={date} />
        </GraphBox>
        <GraphBox
          title="Histórico de Vendas"
          subtext="Veja em detalhes todas as vendas do período"
        >
          <SaleList isLoading={isLoading} sales={sales} />
        </GraphBox>
      </VStack>
    </DashboardTemplate>
  );
}
