import {
  Button,
  Center,
  CircularProgress,
  HStack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { faCheck, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import React, { useState } from "react";
import { updateRegistration } from "../../../../api/registration";

const containerStyle = {
  width: "100%",
  height: "300px",
};

function MapPositionConfirm({ data, storeId, onReturn, onNext }) {
  const toast = useToast();
  const [location, setLocation] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyB5tXrkiapXmzmvkkW0-7MQvfx1LL9VScQ",
  });

  function send() {
    setIsLoading(true);

    updateRegistration(1, {
      id: storeId,
      latitude: location.lat(),
      longitude: location.lng(),
    })
      .then(() => {
        onNext();
      })
      .catch(() => {
        toast({
          title: "Oops!",
          description:
            "Não foi possível salvar essa alteração, entre em contato com o suporte.",
          status: "error",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const onLoad = function callback(map) {
    let geocoder = new window.google.maps.Geocoder();

    console.log(
      "SEARCHING FOR",
      `${data.number}, ${data.street}, ${data.city}, ${data.state}, ${data.postal_code}, ${data.country}`
    );

    geocoder.geocode(
      {
        address: `${data.number}, ${data.street}, ${data.city}, ${data.state}, ${data.postal_code}, ${data.country}`,
      },
      function (results, status) {
        if (status === "OK") {
          map.setCenter(results[0].geometry.location);
          setLocation(results[0].geometry.location);
        } else {
          toast({
            title: "Oops!",
            description: "Não carregar o mapa, entre em contato com o suporte.",
            status: "error",
          });
        }
        setIsLoading(false);
      }
    );
  };

  return (
    <VStack justify="flex-start" align="flex-start">
      <Text>
        O mapa abaixo representa corretamente a localização do seu
        estabelecimento?
      </Text>
      <Text paddingBottom="20px">
        É possível ajustar a localização arrastando o pino vermelho no centro do
        mapa.
      </Text>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={location}
          zoom={17}
          onLoad={onLoad}
        >
          <Marker
            position={location}
            draggable={true}
            onDragEnd={(e) => setLocation(e.latLng)}
          />
        </GoogleMap>
      ) : (
        <Center>
          <CircularProgress isIndeterminate />
        </Center>
      )}
      <HStack padding="20px 0px" width="100%" justifyContent="space-between">
        <Button onClick={onReturn} colorScheme="gray" variant="ghost">
          <FontAwesomeIcon style={{ marginRight: 10 }} icon={faChevronLeft} />
          Voltar
        </Button>
        <Button
          onClick={send}
          isLoading={isLoading}
          colorScheme="green"
          type="submit"
        >
          Confirmo <FontAwesomeIcon style={{ marginLeft: 10 }} icon={faCheck} />
        </Button>
      </HStack>
    </VStack>
  );
}

export default MapPositionConfirm;
