import { Button, Text, VStack } from "@chakra-ui/react";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const Introduction = ({ onNext }) => {
  return (
    <VStack paddingBottom="15px">
      <Text>
        No formulário a seguir você confirmará os dados previamente cadastrados.
      </Text>
      <Text>
        Além disso, também serão pedidos dados extras sobre a sua loja e
        empresa. Qualquer dúvida entre em contato com a gente pelo email:
        suporte@partiu.com.br
      </Text>
      <Text paddingBottom="10px">
        Após a confirmação dos dados, nossa equipe analisará os dados enviados e
        darão seguimento ao processo.
      </Text>
      <Button alignSelf="flex-end" onClick={onNext} colorScheme="green">
        Começar{" "}
        <FontAwesomeIcon style={{ marginLeft: 10 }} icon={faChevronRight} />
      </Button>
    </VStack>
  );
};
