import { Heading, Input, VStack, Text } from "@chakra-ui/react";
import React from "react";

export const LabeledInput = React.forwardRef(
  (
    {
      label,
      detail = "",
      containerStyle = {},
      children,
      Component = Input,
      forceDisable = false,
      ...props
    },
    ref
  ) => (
    <VStack
      style={containerStyle}
      width="100%"
      spacing={3}
      alignItems="flex-start"
    >
      <VStack width="100%" spacing={2} alignItems="flex-start">
        <Heading fontSize="12px !important">{label}</Heading>

        {forceDisable && props.disabled ? (
          <Input
            height="30px"
            borderRadius="5px"
            fontSize="13px"
            ref={ref}
            {...props}
          />
        ) : (
          <Component
            height="30px"
            borderRadius="5px"
            fontSize="13px"
            ref={ref}
            {...props}
          >
            {children}
          </Component>
        )}
        {detail ? (
          <Text fontSize="10px" color="#888888">
            {detail}
          </Text>
        ) : (
          <></>
        )}
      </VStack>
    </VStack>
  )
);
