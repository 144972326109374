import React, { useEffect } from "react";
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Heading,
  HStack,
  Text,
  useToast,
  VStack,
  Select,
  Textarea,
  AbsoluteCenter,
  CircularProgress,
} from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { createRegistration, updateRegistration } from "../../api/registration";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { LabeledInput } from "../molecules/LabeledInput";

export const DiscountForm = ({
  onNext,
  companyId,
  storeId,
  initialData,
  onReturn,
  nextText = "Próximo",
  updateFunc = updateRegistration,
  createFunc = createRegistration,
}) => {
  const toast = useToast();
  const { register, handleSubmit, setValue, watch, getValues } = useForm();
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(getValues("type"));
  const [mode, setMode] = useState(getValues("mode"));
  const [allowed, setAllowed] = useState(false);
  const [others, setOthers] = useState(false);
  const [rules, setRules] = useState([]);
  const [isLoadingInitialData, setIsLoadingInitialData] = useState(true);

  async function onSubmit(data) {
    let func;
    let discount;

    data["company"] = companyId;
    data["stores"] = [storeId];
    data["active"] = true;
    data["rules"] =
      rules.join("\n") +
      (data["rules_other"] && others ? "\n\n" + data["rules_other"] : "");

    setLoading(true);

    func = initialData["id"] ? updateFunc : createFunc;

    try {
      discount = await func(2, data);
      setLoading(false);
    } catch (response) {
      setLoading(false);
      return toast({
        title: "Oops!",
        description:
          "Algo deu errado, verifique as informações e tente novamente.",
        status: "error",
      });
    }

    onNext(discount);
  }

  watch((data, { name }) => {
    if (name === "type") {
      let _type = data[name];
      setType(data[name]);

      if (initialData.name) return;

      if (_type === "buy_and_gain") setValue("name", "Compre e Ganhe!");
      else if (_type === "treat") setValue("name", "Mimo");
    } else if (name === "discount") {
      if (initialData.name) return;
      setValue("name", `${data[name]}% de desconto na conta!`);
    } else if (name === "mode") {
      setMode(data[name]);
    }
  });

  useEffect(() => {
    if (!initialData) return setIsLoadingInitialData(false);

    Object.entries(initialData).forEach(([key, value]) => {
      if (key === "rules" && value) {
        const split = value.split("\r\n\r\n");

        setValue("rules", split[0]);
        setRules(split[0].split("\r\n"));

        if (split.length > 1) {
          setValue("rules_other", split.slice(1));
          setOthers(true);
        }
      }

      setValue(key, value);
    });

    setIsLoadingInitialData(false);
  }, [initialData]);

  if (isLoadingInitialData) {
    return (
      <AbsoluteCenter>
        <CircularProgress isIndeterminate />
      </AbsoluteCenter>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Text fontSize="14px" color="#888888">
        Os campos abaixo são referentes ao desconto que será providenciado.
      </Text>
      <VStack paddingBottom={5} paddingTop={5} spacing={5}>
        <LabeledInput
          Component={Select}
          {...register("mode")}
          value={mode}
          label="Modo de desconto"
          placeholder="Selecione o modo de desconto"
        >
          <option value="local">Local</option>
          <option value="online">Online</option>
        </LabeledInput>
        <LabeledInput
          {...register("type")}
          Component={Select}
          label="Tipo do Desconto"
          value={type}
          placeholder="Selecione o tipo do desconto"
        >
          <option value="discount">Porcentagem</option>
          <option value="buy_and_gain">Compre e Ganhe</option>
          <option value="treat">Mimo</option>
        </LabeledInput>
        <LabeledInput
          type="number"
          min={type === "discount" ? (mode === "online" ? 5 : 10) : 0}
          max={100}
          label={`Porcentagem do desconto (${
            mode === "online" ? "5" : "10"
          }% até 100%)`}
          {...register("discount")}
          required={type === "discount"}
          containerStyle={{ display: type === "discount" ? "block" : "none" }}
        />
        <LabeledInput
          {...register("name")}
          required={true}
          placeholder="10% na conta, Compre x ganhe y, Visite e ganhe x"
          label="Título do desconto (como aparecerá na listagem do app)"
        />
        <VStack width="100%" alignItems="flex-start">
          <Heading paddingBottom="10px">Regulamento</Heading>
          <CheckboxGroup
            defaultValue={rules}
            alignItems="flex-start"
            colorScheme="green"
            onChange={(e) => setRules(e)}
          >
            <VStack width="100%" alignItems="flex-start">
              <Checkbox value="Não cumulativa com outras promoções.">
                <Text fontSize="14px !important">
                  Não cumulativa com outras promoções.
                </Text>
              </Checkbox>
              <Checkbox value="Exceto Delivery.">
                <Text fontSize="14px !important">Exceto Delivery.</Text>
              </Checkbox>
              <Checkbox value="Promoção válida somente para essa unidade.">
                <Text fontSize="14px !important">
                  Promoção válida somente para essa unidade.
                </Text>
              </Checkbox>
              <Checkbox
                value="Sujeito a disponibilidade do produto e lotação do
                    estabelecimento."
              >
                <Text fontSize="14px !important">
                  Sujeito a disponibilidade do produto e lotação do
                  estabelecimento.
                </Text>
              </Checkbox>
            </VStack>
          </CheckboxGroup>
          <Checkbox
            colorScheme="green"
            isChecked={others}
            onChange={(e) => setOthers(e.target.checked)}
          >
            <Text fontSize="14px !important">Outros</Text>
          </Checkbox>
          {others ? (
            <Textarea fontSize="14px" {...register("rules_other")} />
          ) : (
            <></>
          )}
          <Checkbox
            colorScheme="green"
            paddingTop="30px"
            isChecked={allowed}
            onChange={(e) => setAllowed(e.target.checked)}
          >
            <Text fontSize="14px !important">
              <b>
                Entendo que o desconto só será disponiblizado quando aprovado
                pela Partiu!
              </b>
            </Text>
          </Checkbox>
        </VStack>
        <HStack
          width="100%"
          justifyContent={onReturn ? "space-between" : "flex-end"}
        >
          <Button
            display={onReturn ? "initial" : "none"}
            onClick={onReturn}
            colorScheme="gray"
            variant="ghost"
          >
            <FontAwesomeIcon style={{ marginRight: 10 }} icon={faChevronLeft} />
            Voltar
          </Button>
          <Button
            alignSelf="flex-end"
            isLoading={loading}
            disabled={!allowed}
            colorScheme="green"
            type="submit"
          >
            {nextText}
            <FontAwesomeIcon style={{ marginLeft: 10 }} icon={faCheck} />
          </Button>
        </HStack>
      </VStack>
    </form>
  );
};
