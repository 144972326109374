import React from "react";

let initialCompanyData = {
  company: null,
  store: null,
  email: null,
};

export { initialCompanyData };

export const CompanyContext = React.createContext(initialCompanyData);
