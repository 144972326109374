import {
  Box,
  Button,
  Center,
  CircularProgress,
  HStack,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";
import { faFrown, faSadCry } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Sale } from "./Sale";
import { getUnvalidatedVirtualCards } from "../../api/virtual_cards";
import { CompanyContext } from "../../contexts/CompanyContext";
import { useQuery } from "react-query";
import { VirtualCardHistory } from "./VirtualCardHistory";
import { Pagination } from "../atoms/Pagination";

export function VirtualCardHistoryList() {
  const { store } = useContext(CompanyContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const { data: virtualCards = { results: [], count: 0 }, isLoading } = useQuery(["unvalidatedVirtualCard", currentPage], () =>
    getUnvalidatedVirtualCards(store.id, currentPage)
  );

  useEffect(() => {
    if (currentPage !== 1) return;

    setTotalPages(Math.ceil(virtualCards.count / (virtualCards.results.length || 1)));
  })

  if (isLoading)
    return (
      <Center>
        <CircularProgress isIndeterminate />
      </Center>
    );

  if (virtualCards.results.length === 0 && currentPage === 1) {
    return (
      <VStack width="100%" paddingTop="50px" spacing={5} justify="center">
        <FontAwesomeIcon size="4x" icon={faFrown} />
        <Text textAlign="center" fontSize="16px">
          Oops! Parece que não temos nenhuma venda ainda.
          <br /> Está com dúvidas? Entre em contato pelo email:
          suporte@partiu.com.br
        </Text>
      </VStack>
    );
  }

  return (
    <VStack width="100%">
      {virtualCards.results.map((virtualCard, index) => (
        <VStack spacing={5} width="100%" padding="10px 0px">
          <VirtualCardHistory virtualCard={virtualCard} />
          {index < virtualCards.results.length - 1 ? (
            <Box width="100%" borderTop="1px solid #eeeeee" />
          ) : (
            <></>
          )}
        </VStack>
      ))}
      <Pagination {...{ setCurrentPage, currentPage, totalPages }} />
    </VStack>
  );
}
