import {
  Avatar,
  Button,
  Heading,
  HStack,
  Stack,
  useToast,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { ThemeContext } from "../../contexts/ThemeContext";
import { LabeledInput } from "./LabeledInput";
import { LabeledText } from "./LabeledText";
import CurrencyInput from "react-currency-input-field";
import { createSale } from "../../api/sales";

export function VirtualCard({
  user,
  code,
  discount,
  created_at,
}) {
  const toast = useToast();
  const { secondaryColor, secondaryTextColor } = useContext(ThemeContext);
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState(null);
  const [done, setDone] = useState(false);
  const [force, setForce] = useState(false);

  function send() {
    setIsLoading(true);
    createSale(discount.id, code, value.replace(",", "."), force)
      .then(({ status }) => {
        if (status === 202) {
          setForce(true);
          toast({
            title: "Atenção",
            description:
              "Usuário já usou este desconto hoje, deseja gerar outra venda? (Aperte novamente em gerar venda para confirmar)",
            status: "warning",
          });
          return;
        }

        setDone(true);
        toast({
          title: "Sucesso!",
          description: `Venda de R$ ${value} gerada com sucesso!`,
          status: "success",
        });
      })
      .catch(() => {
        toast({
          title: "Erro",
          description: `Não foi possível gerar esta venda, entre em contato com o suporte.`,
          status: "error",
        });
      })
      .finally(() => setIsLoading(false));
  }

  if (done) {
    return <></>;
  }

  return (
    <Stack
      direction={{ base: "column", md: "row" }}
      justify="space-between"
      align={{ base: "flex-start", md: "center" }}
      spacing={5}
      width="100%"
    >
      <Avatar src={user.image} />
      <LabeledText label="Nome Completo">{user.full_name}</LabeledText>
      <LabeledText label="Código">{code}</LabeledText>
      <LabeledText label="Desconto">{discount.name}</LabeledText>
      <LabeledText label="Criado em:">
        {new Date(created_at).toLocaleDateString("pt-BR")}
      </LabeledText>
      <LabeledInput
        containerStyle={{ width: "200px" }}
        label="Valor da Venda"
        value={value}
        onValueChange={(value, name) => setValue(value)}
        prefix="R$ "
        defaultValue={0}
        decimalsLimit={2}
        intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
        Component={CurrencyInput}
        style={{
          border: "1px solid #718096",
          padding: "5px",
          borderRadius: "0.3rem",
        }}
      />
      <Button
        colorScheme="orange"
        backgroundColor={secondaryColor}
        color={"white"}
        isLoading={isLoading}
        disabled={value == null}
        onClick={send}
      >
        Registrar Venda
      </Button>
    </Stack>
  );
}
